.menuBar {
  width: 280px;
  height: 100vh;
  overflow: scroll;
}
.menuPageContainer {
  width: calc(100% - 280px);
}
.scrollArea {
  height: calc(100vh - 65px);
  overflow: scroll;
}
.dashboardLogo {
  width: 180px;
  height: 65px;
  background: #ccc;
  margin: 0 auto;
  /* margin-top: 10px; */
}

.ant-pro-table-column-setting-list {
  display: flex;
  flex-direction: column;
  /* width: 168px; */
  width: 100%;
}
.ant-pro-table-column-setting-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 26px;
}

.ant-pro-table-column-setting-list-item-option {
  display: none;
  float: right;
  cursor: pointer;
}
.ant-pro-table-column-setting-list-item:hover
  .ant-pro-table-column-setting-list-item-option {
  display: block;
}
.ant-pro-table-column-setting-list-item-option > span + span {
  margin-left: 8px;
}
.ant-layout-sider-trigger {
  background: #16a085;
}
.border-red-500 {
  --border-opacity: 1;
  border-color: #f56565 !important;
}

.ant-modal-body {
  padding: 32px;
}

.ant-modal-close-x {
  width: 32px;
  height: 32px;
  line-height: 32px;
  background-color: white;
}

.ant-upload-list-item-name {
  cursor: pointer;
}

.react-images__footer__caption {
  font-size: 20px;
  /* background-color: #ffffff50; */
  background-color: #00000050;
  padding: 10px;
  border-radius: 3px;
}

.spinner {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.ant-input-number-input-wrap {
  width: 100%;
}

thead > tr > th {
  background-color: #16a085 !important;
  color: white !important;
}

.ant-layout-header {
  z-index: 1060 !important;
  padding-right: 0px;
}

.ant-notification {
  z-index: 1070 !important;
}

.ant-pagination-options {
  display: none;
}

.ant-popover {
  z-index: 1060;
}

.anticon {
  vertical-align: 0em !important;
}

.navlinkClassName > div {
  background: #16a08550;
  border-right: 3px solid #16a085;
  color: #16a085;
}

.partnerNavlinkClassName {
  border-bottom: 3px solid #16a085;
}

.modal-title-center .ant-modal-title {
  text-align: center;
}

.ant-modal-close {
  display: none;
}

.modal-title-center .ant-modal-footer,
.modal-title-center .ant-modal-header {
  border: none !important;
  text-align: center;
}

@media only screen and (max-width: 1600px) {
  .ant-steps-item-title {
    font-size: 14px;
  }
}

.ant-picker-now-btn {
  display: none;
}

.react-images__view-image {
  max-height: 100vh !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #16a085;
  color: white;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #16a08550;
}

.DayPicker-Week {
  line-height: 1;
}

@media screen and (max-width: 400px) {
 .oro-offer .ant-table {
    overflow-x: auto !important;
    width: 325px !important;
  }
}

.simple-table thead > tr > th {
  background-color: rgba(51, 51, 51, 0.1) !important;
  color: black !important;
  font-weight: bold !important;
  font-size: 15px !important;
}

.primary-table thead > tr > th {
  background-color: rgba(255, 195, 81, 0.3) !important;
  color: black !important;
  font-weight: bold !important;
  font-size: 15px !important;
}

.oro-offer .ant-table-cell {
  border: 0.01em solid rgba(205, 205, 205, 0.7) !important;
}

.oro-offer.ant-table-selection-column {
  width: 2% !important;
}

.oro-offer .ant-checkbox-wrapper {
  /*flex: 1;*/
  align-items: center !important;
  justify-content:center !important;
}

/* tbody > tr > td {
  font-weight: 700 !important;
  color: black !important;
} */

.upgraded-primary-bg {
  background-color: #FFC351 !important;
}

.upgraded-secondary-bg {
  background-color: rgba(255, 195, 81, 0.3) !important;
}

.upgraded-primary-color {
  color: #FFC351 !important;
}

.upgraded-selector:hover {
  color: #FFC351;
}

.table__pagination {
  width: 90% !important;
}

.upgraded-primary-btn {
  background-color: #FFC351;
  color: black;
}

.upgraded-success-btn {
  background-color: #16A085;
  color: white;
}

.upgraded-success-btn:hover {
  background-color: #20c5a4;
  cursor: pointer;
}

.upgraded-primary-btn:hover {
  cursor: pointer;
  background-color: #edb040;
}

.upgraded-danger-btn {
  background-color: #CF2121;
  color: white;
}

.upgraded-danger-btn {
  background-color: #CF2121;
  color: white;
}

.upgraded-disabled-btn {
  background-color: #cccccc80;
  color: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
}

.upgraded-danger-btn:hover {
  cursor: pointer;
  background-color: #e42e2e;
}

.ant-modal-title {
  padding-top: 15px !important;
  font-weight: 800 !important;
  font-size: 18px !important;
  margin-left: 12px !important;
}

.ant-modal-footer {
  border-top: 1px white !important;
}

.ant-modal-header {
  border-bottom: 1px white !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#notifications-menu {
  height: 200px;
  overflow: scroll;
}
