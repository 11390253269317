.ant-input-bg,
.ant-input-password,
.ant-select-selector {
  display: flex;
  font-family: inherit;
  font-size: 16px;
  /* height: 50px !important; */
  /* max-height: 50px !important; */
  height: 38px !important;
  /* max-height: 60px !important; */
  font-weight: 400 !important;
  margin: 0px;
  max-width: 100%;
  border: none !important;
  /* background-color: #edeef3 !important; */
  border-radius: 8px !important;
  /* color: #212121; */
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #edeef3 !important;
}
form button {
  height: 38px !important;
  min-width: 150px;
}
button {
  max-height: 40px;
}
.ant-input-password .ant-input {
  background: inherit;
}
/* .ant-input-bg {
  padding: 16px;
  padding-right: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 1.45;
} */
.ant-input-bg.white {
  background: #fff;
}
.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}
.ant-picker-input > input {
  text-align: center;
}
.ant-select-selection-overflow
  > .ant-select-selection-overflow-item
  > .ant-select-selection-item {
  padding-top: 0 !important;
}
.ant-select-selection-item,
.ant-select-selection-placeholder {
  /* line-height: 30px !important; */
  padding-top: 0.25rem !important;
}
.ant-picker {
  line-height: 0px !important;
}
/* .anticon svg {
  height: 22px;
} */
.ant-input-number-handler-wrap {
  display: none;
}
.ant-select-arrow > span > svg {
  height: 12px;
}
.ant-table-wrapper .anticon svg {
  height: initial;
}

.ant-input-v2-bg {
  display: flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400 !important;
  margin: 0 0 4px;
  max-width: 100%;
  border: none !important;
  background-color: transparent;
  border-radius: 0px !important;
  color: #212121;
  width: 100% !important;
  box-sizing: border-box;
}
.ant-input-v2-bg:focus,
.V2 .ant-input-password:focus {
  background: transparent;
  outline: none;
  box-shadow: none;
}
.inputContainer {
  background: #fff;
  /* box-shadow: 0 2px 8px #f0f1f2; */
  border: 1px solid #ccc;
}
.V2 .ant-input-password {
  background: none !important;
  border: none !important;
  height: initial !important;
  max-height: initial !important;
  padding: 0;
  padding-left: 10px;
  margin-bottom: 0;
  box-shadow: none;
  outline: none;
}
.V2 .ant-input-password .ant-input {
  line-height: 1.25;
}
.ant-upload-list {
  /* display: none; */
  position: absolute;
  left: 0;
  /* height: 60px; */
}
/* MAP ZOOM IN, ZOOM OUT BTNS */
.gmnoprint > div > .gm-control-active {
  min-width: unset !important;
}
.ant-slider {
  margin-bottom: 0;
}
